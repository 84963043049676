/**
 * Utils class for common methods.
 */
export function Utils() {
  /**
   * Selects a lab hours table from the page.
   *
   * @param {number} i              The lab hours table to select from the page.
   * @param {string} tableSelector  The table selector to get the html element
   */
  function getHoursTable(i, tableSelector) {
    return $(tableSelector).eq(i);
  }

  /**
   * Shows a lab hours table.
   *
   * @param {number} i              The lab hours table to show on the page.
   * @param {string} tableSelector  The table selector to get the html element
   */
  function showHoursTable(i, tableSelector) {
    getHoursTable(i, tableSelector).show();
  }

  /**
   * Hide a lab hours table.
   *
   * @param {number} i              The lab hours table to hide on the page.
   * @param {string} tableSelector  The table selector to get the html element
   */
  function hideHoursTable(i, tableSelector) {
    getHoursTable(i, tableSelector).hide();
  }

  /**
   * Add listener and action to the show/hours HTML element
   *
   * @param {string} elementClass   Class selector to get the element
   * @param {string} tableSelector  The table selector to get the html element
   */
  function showHoursListener(elementClass, tableSelector) {
    $(elementClass).each(function (i, elem) {
      $(elem.children[0]).on('click', function () {
        showHoursTable(i, tableSelector);
        $(elem.children[0]).hide();
        $(elem.children[1]).show();
      });
      $(elem.children[1]).on('click', function () {
        hideHoursTable(i, tableSelector);
        $(elem.children[1]).hide();
        $(elem.children[0]).show();
      });
    });
  }

  /**
   * Format date into AM - PM hours
   *
   * @param date
   * @returns {string}
   */
  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return hours + ':' + minutes + ' ' + ampm;
  }

  return {
    showHoursListener,
    formatAMPM,
  };
}
