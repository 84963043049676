import { Utils } from './utils';

$(function () {
  const labSearchInput = $('#findNearLab-zipCode');
  const utils = new Utils();

  utils.showHoursListener('.hours-show-hide', 'table.labHours');

  $('#findNearLabSubmit').on('click', submitLabSearch);

  labSearchInput.on('keypress', function (event) {
    if (event.key === 'Enter') {
      submitLabSearch();
    }
  });

  /**
   * Sets the preferred zip code into local storage then navigates to the findalab page.
   */
  function submitLabSearch() {
    const zipCode = labSearchInput.val();
    if (zipCode.length !== 5) {
      $('#findNearLabSubmitError').show();

      return;
    }
    window.localStorage.setItem('ngStorage-zip_code', JSON.stringify(zipCode));
    window.location.href = '/std-test-center.php';
  }
});
